import React from "react"
import img from "../images/our-approach.jpg"
import OurApproachContent from "./our-approach-content"

const OurApproach = ({landingPage}) => {
  return (
    <div
      className="relative py-4 overflow-x-hidden bg-white sm:py-0"
      id="success"
    >
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
        <div
          className="relative sm:py-16 lg:py-0">

          <div className="relative max-w-md px-0 mx-auto sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-0">
            <div className=" pb-0 pt-10">
                <img
                  className="inset-0 w-full h-full"
                  src={img}
                  alt=""
                />
            </div>
          </div>
        </div>

        <div className="relative max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-0">
          <div className="pt-12 sm:pt-16 lg:pt-20">
            <h2 className="text-3xl font-extrabold tracking-tight text-black sm:text-4xl">
              Our approach is simple
            </h2>
            <div className="mt-6 space-y-6 text-black">
              <div dangerouslySetInnerHTML={{ __html: OurApproachContent(landingPage, false) }} />
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default OurApproach
