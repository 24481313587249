import React, { Fragment } from "react"
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'
import logo from "../images/logo-header-black.svg"
import { Helmet } from "react-helmet"

import Menu from "./menu"

export default function Header() {
  return (
    <div className="relative bg-black">

      &#160;
      <main className="mx-auto max-w-7xl px-4 sm:px-6 mx-auto max-w-7xl px-4 mt-8 sm:mt-8 sm:px-6 lg:mt-8">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h1>
              <span className="text-green mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                <span className="">DON’T trade</span><br/>
                <span className="">your own money.</span><br/>
                <span className="">Trade a LIVE funded account!</span>
              </span>
            </h1>
            <div className="mt-3 text-base sm:mt-5 sm:text-xl lg:text-xl xl:text-xl text-white">
              Receive 75% or 90% of the gains, we cover your losses.
              <br />
              <div style={{ fontSize: "small" }}>
                <i>*75% account gains share with standard TFP account, but can receive 90% at an extra initial cost.</i>
              </div>
            </div>

            <div className="mt-10 mb-10 sm:flex sm:justify-center lg:justify-start">
              <div className="shadow">
                <a
                  href="/#pricing-plans"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium text-white face-blue-bg face-blue-btn-hover md:py-4 md:text-lg md:px-10"
                >
                  Start Now
                </a>
              </div>
            </div>

          </div>
          <div className="face-black pb-20 mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div className="relative mx-auto w-full shadow-lg lg:max-w-xl">

                <span className="sr-only">Watch our video to learn more</span>
                <div className="relative text-center aspect-w-16 aspect-h-9">
                  <iframe
                    loading="lazy"
                    title="video"
                    className="absolute top-0 left-0 w-full h-full"
                    src="https://www.youtube.com/embed/qIyfGuJiBs4"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>

            </div>
          </div>
        </div>
      </main>

    </div>
  )
}
