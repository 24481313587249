import React from "react"
import logo from "../images/forex-logo-white-small.svg"
import logo2 from "../images/FT-logo-white-small.svg"

function Testimonials() {
  const items = [
    {
      title: "Trader Funding Program",
      logo: logo,
      review: "Trader Funding Program",
      author: "Powered by",
      position: "Forex Analytix"
    },
    {
      title: "",
      logo: logo2,
      review: "The Trader Funding Program – take the next step in your trading journey and join a community of successful traders.",
      author: "",
    }

  ]
  return (
    <section className="bg-black">
      <div className="mx-auto max-w-7xl md:grid md:grid-cols-2 md:px-6 lg:px-8">

        {items?.map((item, index) => (
          <div
            key={index}
            className={
              index % 2 === 0
                ? "px-4 py-12 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-gray-700 lg:pr-16"
                : "px-4 py-12 border-t-2 border-gray-700 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16"
            }
          >
            {item?.logo && (
              <div className="md:flex-shrink-0">
                <img
                  className="h-12"
                  src={item?.logo}
                  alt={item?.logo?.title}
                />
              </div>
            )}
            <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
              <div className="relative text-lg font-medium text-green md:flex-grow">
                <svg
                  className="absolute top-0 left-0 w-8 h-8 text-indigo-900 transform -translate-x-3 -translate-y-2"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                {item?.review && <p className="relative">{item?.review}</p>}
              </div>
              <footer className="mt-8">
                <div className="flex items-start">
                  {(item?.author || item?.position) && (
                    <div>
                      {item?.author && (
                        <div className="text-base font-medium text-white">
                          {item?.author}
                        </div>
                      )}
                      {item?.position && (
                        <div className="text-base font-medium text-gray-300">
                          {item?.position}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </footer>
            </blockquote>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Testimonials
