import React from "react"

function PresentationVideo() {
  return (
    <div className="py-12 bg-gray-50 lg:py-24" id="presentation-video">
        <div className="mb-16">
          <div className="px-4 mx-auto text-center max-w-7xl sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto space-y-2 space-y-6 lg:max-w-none">

                <h2 className="text-lg font-semibold leading-6 tracking-wider text-indigo-600 uppercase">
                  A PRESENTATION FROM NICK OF JUPITER
                </h2>

                <p className="text-3xl font-extrabold sm:text-4xl lg:text-5xl">
                  Pros and Cons of Trading Prop
                </p>

                <p className="text-xl text-black">
                  Nick shares his insights on the advantages of using funded trading programs to trade and make money.
                </p>

            </div>
          </div>
        </div>

        <div className="max-w-4xl mx-auto">
          <div className="relative text-center aspect-w-16 aspect-h-9">
            <iframe
              title="video"
              className="absolute top-0 left-0 w-full h-full"
              src="https://www.youtube.com/embed/LeWaGCgD5VE"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>

    </div>
  )
}

export default PresentationVideo
