import React from "react"

import img from "../images/why-forex.jpg"

function OurCommunity({ data }) {
  const title = 'why-forex'

  return (
    <div className="relative bg-black">
      <div
        className="md:left-0 h-56 bg-indigo-600 sm:h-72 md:absolute md:h-full md:w-1/2">
          <img
            className="object-cover w-full h-full"
            src={img}
            alt={title}
          />
      </div>
      <div className="relative px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:pl-10 md:w-1/2">
            <h2 className="text-base font-semibold tracking-wider text-gray-100 uppercase">
              OUR COMMUNITY
            </h2>
            <p className="mt-2 text-3xl font-extrabold tracking-tight text-green sm:text-4xl">
              Why is trading with Forex Analytix different?
            </p>
            <p className="mt-3 text-lg text-gray-100">
              We have been running multiple daily webinars for years. But what is more important is our live chat rooms where traders of every type (full time, part time, ex bank traders, etc) thrive daily in our community.
            </p>

        </div>
      </div>
    </div>
  )
}

export default OurCommunity
