import React from "react"

const social = [
  {
    name: "Twitter",
    href: "https://twitter.com/forexanalytix",
    icon: props => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
      </svg>
    ),
  },
  {
    name: "YouTube",
    href: "https://www.youtube.com/forexanalytix",
    icon: props => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          d="M21,5c0,0-3-1-9-1S3,5,3,5s-1,3-1,7s1,7,1,7s3,1,9,1s9-1,9-1s1-3,1-7S21,5,21,5z M10,15.464V8.536L16,12L10,15.464z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/traderfundingprogram/",
    icon: props => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          d="M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: "Facebook",
    href: "https://www.facebook.com/forexanalytix",
    icon: props => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/forexanalytix",
    icon: props => (
      <svg fill="currentColor" viewBox="0 0 50 50" {...props}>
        <path
          fillRule="evenodd"
          d="M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M18,35h-4V20h4V35z M16,17 c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C18,16.105,17.105,17,16,17z M37,35h-4v-5v-2.5c0-1.925-1.575-3.5-3.5-3.5 S26,25.575,26,27.5V35h-4V20h4v1.816C27.168,20.694,28.752,20,30.5,20c3.59,0,6.5,2.91,6.5,6.5V35z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
]

const menuItems = [
  { label: 'Forex Analytix', url: 'https://www.forexanalytix.com/' },
  { label: 'Our Team', url: 'https://www.forexanalytix.com/team' },
  { label: 'Blog', url: 'https://www.forexanalytix.com/blog/' },
  { label: 'Youtube Channel', url: 'https://youtube.com/forexanalytix/' },
  { label: 'Privacy Policy', url: '/privacy' },
];


const Footer = ({ data }) => {

  return (
    <footer className="bg-black">
      <div className="px-4 py-12 mx-auto overflow-hidden max-w-7xl sm:px-6 lg:px-8 pb-52">
        {
          <nav
            className="flex flex-wrap justify-center -mx-5 -my-2"
            aria-label="Footer"
          >
            {menuItems?.map((item, index) => (
              <div key={index} className="px-5 py-2">
                <a
                  href={item?.url}
                  className="text-base text-green hover:underline"
                >
                  {item?.label}
                </a>
              </div>
            ))}
          </nav>
        }

        <div className="flex justify-center mt-8 space-x-6">
          {social?.map((item, index) => (
            <a
              key={index}
              href={item.href}
              className="text-gray-300 face-green-hover"
              target="_blank"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="w-6 h-6" aria-hidden="true" />
            </a>
          ))}
        </div>

        <p className="max-w-3xl mx-auto mt-8 text-base text-center1 text-gray-100">
          DISCLAIMER: Trader Funding Program is an affiliate of Prop Account, LLC. All funding assessments are provided by Prop Account, LLC and all assessment fees are paid to Prop Account, LLC. If you qualify for a Funded Account, you will be required to enter into a Trader Agreement with Prop Account LC. Neither Prop Account, LLC nor Prop Account LC provides any trading education or other services. All such services are provided by Trader Funding Program.
        </p>
        <p className="max-w-3xl mx-auto mt-8 text-base text-center1 text-gray-100">
          CLIENT TERMS, CONDITIONS AND POLICIES:
          Please find our terms, conditions and policies here: <a className="underline" href="https://dashboardanalytix.com/client-terms-and-policies/?v=f214a7d42e0d" target="_blank">https://dashboardanalytix.com/client-terms-and-policies</a>
        </p>
        <p className="max-w-3xl mx-auto mt-8 text-base text-center1 text-gray-100">
          CLICK <a className="underline" href="/client-terms">
            HERE
          </a> FOR CLIENT TERMS, CONDITIONS AND POLICIES FULL TEXT
        </p>
        <p className="mt-8 mb-12 text-base text-center text-gray-100">
          &copy; 2023 Trader Funding Program by <a className="primary underline" href="https://www.forexanalytix.com" target="_blank">ForexAnalytix</a>. All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
