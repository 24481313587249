/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Helmet } from "react-helmet"

function Seo({ lang, meta, landingPage }) {

  // default page /index
  let title = "Trader Funding Program | Funded Trading program | Prop-Proprietary trading |";
  let description = "Want to become a funded trader? Traderfundingprogram.com by Forex Analytix offers program for trader funding. We take all the risk and you keep 75% of the gains.";
  let keywords = "Trader Funding Program,funded trading,funded trading program,funded trader,trader funding, USA, UK, Australia, Canada, India, New York, London, Sydney, Toronto, Mumbai"

  if (landingPage == 'usa-new-york') {
    title = "Trader Funding Program in USA, New York | Funded trader program | Prop-Proprietary trading |";
    description = "Want to become a funded trader? Traderfundingprogram.com by Forex Analytix offers program for trader funding. Get your account today and improve your trading skills.";
    keywords = "Trader Funding Program,funded trading,funded trading program,funded trader,trader funding, USA, New York"
  }
  else if (landingPage == 'uk-london') {
    title = "Trader Funding Program in UK, London | Funded trader program | Prop-Proprietary trading |";
    description = "Want to become a funded trader? Traderfundingprogram.com by Forex Analytix offers program for trader funding. We cover your losses and you keep 75% of the gains.";
    keywords = "Trader Funding Program,funded trading,funded trading program,funded trader,trader funding, UK, London"
  }
  else if (landingPage == 'canada-toronto') {
    title = "Trader Funding Program in Canada, Toronto | Funded trader program | Prop-Proprietary trading |";
    description = "Want to become a funded trader? Traderfundingprogram.com by Forex Analytix offers program for trader funding. Trade with your own live funded account today.";
    keywords = "Trader Funding Program,funded trading,funded trading program,funded trader,trader funding, Canada, Toronto"
  }
  else if (landingPage == 'australia-sydney') {
    title = "Trader Funding Program in Australia, Sydney | Funded trader program | Prop-Proprietary trading |";
    description = "Want to become a funded trader? Traderfundingprogram.com by Forex Analytix offers program for trader funding. Start trading today with the support of our community.";
    keywords = "Trader Funding Program,funded trading,funded trading program,funded trader,trader funding, Australia, Sydney"
  }
  else if (landingPage == 'india-mumbai') {
    title = "Trader Funding Program in India, Mumbai | Funded trader program | Prop-Proprietary trading |";
    description = "Want to become a funded trader? Traderfundingprogram.com by Forex Analytix offers program for trader funding. Get your account today and improve your trading skills.";
    keywords = "Trader Funding Program,funded trading,funded trading program,funded trader,trader funding, India, Mumbai"
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        // titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            name: `keywords`,
            content: keywords,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:keywords`,
            content: keywords,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `og:image`,
            content: `https://traderfundingprogram.com/trader-funding.png`,
          },
          {
            name: `og:image:width`,
            content: `1024`,
          },
          {
            name: `og:image:height`,
            content: `512`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:creator`,
            content: `Forex Analytix`,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: description,
          },
          {
            name: `twitter:image`,
            content: `https://traderfundingprogram.com/trader-funding.png`,
          },
        ].concat(meta)}
      >

      </Helmet>
    </>

  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default Seo
