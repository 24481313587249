import React, { Fragment } from "react"
import { CheckIcon, CheckCircleIcon} from "@heroicons/react/outline"

function PricingCard(item) {
  const { title, description, monthlyPrice, features } = item.data

  const openLink = () => {
    let ref;
    let affiliateid;

    if (typeof window !== 'undefined') {
      ref = localStorage.getItem("ref_code");
      affiliateid = localStorage.getItem("affiliate_id");
    }

    // ref is kept for compatibility with old links that were used to track site traffic source
    if (ref) ref = ref.toLowerCase();

    if (ref == "stelios") affiliateid = '1';
    if (ref == "wtgew22") affiliateid = '8';
    if (ref == "leandro") affiliateid = '5';
    if (ref == "kman") affiliateid = '6';
    if (ref == "forexflow") affiliateid = '7';
    if (ref == "jayne") affiliateid = '3';
    if (ref == "andre") affiliateid = '9';
    if (ref == "blake") affiliateid = '10';
    if (ref == "jack") affiliateid = '11';
    if (ref == "nickofjupiter") affiliateid = '15';
    if (ref == "dale") affiliateid = '12';

    // console.log('affiliateid ', affiliateid)
    // console.log('ref ', ref)
    let url = "https://traderfundingdashboard.propaccount.com/evaluation/";
    if (affiliateid)
      url = url + `?affiliateid=${affiliateid}`

    window.open(url, "_self");
    return false;
  }

  return (
    <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
      <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
        {title && (
          <div>
            <h3
              className="inline-flex px-4 py-1 text-sm font-semibold tracking-wide text-indigo-600 uppercase bg-indigo-100 rounded-full"
              id="tier-standard"
            >
              {title}
            </h3>
          </div>
        )}
        {monthlyPrice && (
          <Fragment>
            <div className="flex items-baseline mt-4 text-6xl font-extrabold">
              ${monthlyPrice.toLocaleString()}
            </div>
            <div className="ml-1 text-2xl font-medium text-black">
              Account Size
            </div>
          </Fragment>
        )}
        {description && (
          <p className="mt-5 text-lg text-black">{description}</p>
        )}
      </div>
      <div className="flex flex-col justify-between flex-1 px-6 pt-6 pb-8 space-y-6 bg-gray-50 sm:p-10 sm:pt-6">
        {features?.length > 0 && (
          <ul className="space-y-4">
            {features?.map((item, index) => (
              <li key={index} className="flex items-start">
                <div className="flex-shrink-0 text-green text-2xl">
                  &#8226;
                </div>
                {item?.title && (
                  <p className="ml-3 text-base text-black" dangerouslySetInnerHTML={{__html: item?.title}}></p>
                )}
              </li>
            ))}
          </ul>
        )}

        <div className="shadow">
          <a
            className="flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent face-blue-bg face-blue-btn-hover cursor-pointer"
            onClick={e => (openLink())}
          >
            Join Now
          </a>
        </div>

      </div>
    </div>
  )
}

export default PricingCard
