import React from "react"

const OurApproachContent = (page, reversed) => {

  if (page == 'usa-new-york') {
    return !reversed ? (
    `
    Prove us that you have the right trading skills <b>on a DEMO account</b> and get funded right away.
    <br/>
    <br/>
    Generate a 10% return without losing more than 6% overall (while adhering to the maximum trailing drawdown level as described below), or losing more than 5% per day, <b>and trade at least once every 30 days</b>. Additionally, there you have the option to carry trades over weekends. In case you haven't purchased this option any open trades will be automatically closed on Friday ahead of the weekend. <b>It’s that simple</b>. There are no hidden requirements, constraints, allocation, or frequency rules. We will give you free access to the Forex Analytix Premium service depending on which <b>funded trading program in USA, New York</b> you choose. This offer is valid for <b>24 months</b> from your first assessment date.
    <br/>
    <br/>
    As soon as you have successfully passed the practical assessment, we will invest in your development by funding your live account. You will be able to trade as you see fit. And if you manage to stay within the drawdown criteria, <b>you will keep 75% of the gains, or 90% if you have purchased this extra option</b>.
    ` ) : (
    `
    After you get funded, you will continue to receive ongoing support from <u><a href="https://www.forexanalytix.com" target="_blank">Forex Analytix</a></u>. You will also be able to join as a guest on our internationally acclaimed FACE (Forex Analytix Community Experience) daily shows as one of our speakers and tell your success story. <b>We want you to succeed!</b>
    <br/>
    <br/>
    We base our recruitment on merit, not location, education, age, or demographics. We want to give everyone an equal chance at becoming a trader.
    <br/>
    <br/>
    When you are in one of our <b>funded trader program</b>, you will have access to advanced performance analytics in an intuitive user dashboard. With this information readily available you will be much more efficient and make the best trading decisions.
    `)
  }
  else if (page == 'uk-london') {
    return !reversed ? (
    `
    Show us that you have the skills to trade by using a <b>DEMO Account</b> and get funded instantly.
    <br/>
    <br/>
    All you have to do is generate a 10% return without losing more than 6% overall (while adhering to the maximum trailing drawdown level as described below), or losing more than 5% per day, <b>and trade at least once every 30 days</b>. Normally, any trades left open will be automatically closed on Friday ahead of the weekend, but you have the option to purchase a plan which allows you to carry trades over weekends. <b>And that’s it!</b> We do not keep anything concealed, no hidden requirements and constraints, allocation rules, or time limits. Depending on which one of the <b>funded trading program in UK London</b> you choose, you get free access to the Forex Analytix Premium service. You'll have <b>up to 24 months of free access</b> from the date of your first assessment.
    <br/>
    <br/>
    As soon as you pass your assessment, we will fund your live account and provide you with the education you need to prop trade successfully. If you stay within the drawdown criteria, <b>you keep 75% of the gains, or 90% if you have purchased this extra option.</b>
    ` ) : (
    `
    After you get funded, you are not left to fend for yourself. <u><a href="https://www.forexanalytix.com" target="_blank">Forex Analytix</a></u> will keep supporting you and you will also participate in our prominent FACE (Forex Analytix Community Experience) shows where you will get to speak as an expert in your field. We are by your side, to make sure you keep exceeding your expectations. <b>We want you to succeed!</b>
    <br/>
    <br/>
    Our recruitment process is completely unbiased and based solely on merit. Your location, education level, your age or employment history are completely irrelevant to us. Our goal is to give everyone a chance to become successful at trading.
    <br/>
    <br/>
    When you're in one of our <b>funded trader program</b>, you'll have access to advanced analytics via a user-friendly dashboard that provides you all the information you need to make better trading decisions that are backed by data and insight.
    `)
  }
  else if (page == 'canada-toronto') {
    return !reversed ? (
    `
    Show us that you have the skills by using a <b>DEMO Account</b>, and we'll help you get started promptly.
    <br/>
    <br/>
    Prove your expertise by generating a 10% return without losing more than 6% overall (while adhering to the maximum trailing drawdown level as described below), or losing more than 5% per day, <b>and trading at least once every 30 days</b>. Any open trades are automatically closed on Fridays, but if you purchase the weekend trading option, your trades will remain open over the weekend. <b>It’s as easy as that.</b> We do not keep any secrets from you. We tell you everything you need to know about the <b>funded trading program in Canada, Toronto</b> we offer: no hidden requirements, no constraints, no allocation rules, and no time limits. You'll have unlimited access to the Forex Analytix <b>Premium service for up to 24 months</b> from the date of your first assessment.
    <br/>
    <br/>
    We invest in your growth by funding your live account as soon as you pass your practical assessment. We will enable you to trade as you wish and <b>keep 75% of the gains, or 90% if you have purchased this extra option</b>, as long as you stay within the drawdown criteria.
    ` ) : (
    `
    Once you're funded, you will have access to our exclusive <u><a href="https://www.forexanalytix.com" target="_blank">Forex Analytix</a></u> community. You'll also have the opportunity to join as a guest on our world-famous FACE (Forex Analytix Community Experience) daily shows and tell your success story. <b>We want you to succeed!</b>
    <br/>
    <br/>
    We want to give an equal opportunity to everyone who wants to be a trader. We truly believe that a person's abilities and hard work, not their social or cultural background, is what matters most in their effort to become a successful trader, thus we are open to anyone who wants to join us.
    <br/>
    <br/>
    When you join one of our <b>funded trader program</b>, you will be able to see advanced performance analytics in an easy-to-understand dashboard and utilize this information to make orderly trading decisions based on quantitative data.
    `)
  }
  else if (page == 'india-mumbai') {
    return !reversed ? (
    `
    Prove your trading acumen on a <b>DEMO account</b> and get started straight away.
    <br/>
    <br/>
    The key to proving it is by generating a 10% return without losing more than 6% overall (while adhering to the maximum trailing drawdown level as described below), or losing more than 5% per day. And we'd also like to see you <b>trade at least once every 30 days</b>. In addition, there is an extra feature that allows you to carry trades over the weekends, if you didn’t close them out by Friday. If you haven’t purchased this feature, open trades will automatically be closed on Friday. <b>It’s as simple as can be.</b> You do not have to worry about any secret terms, conditions, or rules. To help you get started, we will provide you a maximum of 12 months of free access from the date of your first assessment, to our Forex Analytix Premium service, depending on which one of the <b>funded trading program</b> you choose from.
    <br/>
    <br/>
    Once you pass the practical assessment, we will fund your live account and give you complete freedom over how to use it. If you stay within the drawdown criteria, <b>you will keep 75% of the gains, or 90% if you have purchased this extra option.</b>
    ` ) : (
    `
    We at <u><a href="https://www.forexanalytix.com" target="_blank">Forex Analytix</a></u> will continue to reinforce your development, after you get funded. You will also have the opportunity to take part in our exclusive FACE (Forex Analytix Community Experience) daily shows, where you can share your knowledge and experiences with other members of our community. We will always stand by you, to ensure you continue to advance in your prop training journey. <b>We want you to succeed!</b>
    <br/>
    <br/>
    We are completely impartial and recruit people based on objective standards. Your sociocultural background is not relevant to us. Anyone who has the willpower and puts in the work is given an equal opportunity to join us as a <b>funded trader in India, Mumbai.</b>
    <br/>
    <br/>
    Throughout your trading journey, you will gain access to advanced performance analytics, presented to you in a clear an intuitive way. With all this information at your fingertips, you will become more efficient and perfect your trading skills.
    `)
  }
  else if (page == 'australia-sydney') {
    return !reversed ? (
    `
    Demonstrate your trading abilities on a <b>DEMO account</b> and get funded instantly.
    <br/>
    <br/>
    How do you do that? By generating a 10% return without losing more than 6% overall (while adhering to the maximum trailing drawdown level as described below), or losing more than 5% per day, <b>and trading at least once every 30 days</b>. If you wish to carry trades over weekends, you may purchase an option that lets you do so. If you haven’t chosen this option, any open trades will be automatically closed on Friday ahead of the weekend. <b>And that’s that.</b> There aren’t any concealed rules and requirements regarding frequency or time limits. Based on which <b>funded trading program</b> you choose, you get free access to the Forex Analytix Premium service, with a maximum of 12 months accumulated free access from the date of your first assessment.
    <br/>
    <br/>
    After you successfully complete our practical assessment, we will continue to support you and invest in your growth. When your live account gets funded, you ‘ll have the freedom to trade in any way you see fit and <b>keep 75% of the gains, or 90% if you have purchased this extra option,</b> if you manage to stay within the drawdown criteria.
    ` ) : (
    `
    At <u><a href="https://www.forexanalytix.com" target="_blank">Forex Analytics</a></u> we do not stop supporting you once you get funded. You may also join us on our world-famous FACE (Forex Analytix Community Experience) shows and share your insights and your story. We are always next to you, to guarantee you pursue your goals and achieve success. <b>We want you to succeed!</b>
    <br/>
    <br/>
    Throughout our recruitment process, we don’t consider you’re your location, education, or other demographics. Our process is 100% based on merit, because we want to provide an equal chance to everyone who wants to be successful at trading.
    <br/>
    <br/>
    When you join our <b>funded trader program in Australia, Sydney</b> you will gain access to advanced performance analytics, presented in an easy-to-understand way. With this information you will hone your trading skills and make more profitable decisions, based on valid data.
    `)
  }
  else {
    return !reversed ? (
    `
    Prove that you have the right trading skills <b>on a DEMO account</b> and get funded immediately.
    <br/>
    <br/>
    How do you prove it? By generating a 10% return without losing more than 6% overall (while adhering to the maximum trailing drawdown level as described below), or losing more than 5% per day, <b>and trading at least once every 30 days</b>. Furthermore, there is an extra option that you can purchase which allows you to carry trades over weekends – if you haven’t purchased this option any open trades will be automatically closed on Friday ahead of the weekend. <b>It’s as simple as that</b>. There are no other hidden requirements, constraints, frequency or allocation rules, or time limits. We will support you by giving you free access to the Forex Analytix Premium service depending on the account size you choose - with a <b>maximum of 12 months</b> accumulated free access from the date of your first assessment.
    <br/>
    <br/>
    Once you have successfully passed the practical assessment, we will invest in you and support you on your prop trading journey. We will fund your live account which you will then be able to trade as you see fit, while still abiding by the drawdown rules. As long as you stay within the drawdown criteria, <b>you will keep 75% of the gains, or 90% if you have purchased this extra option.</b>
    ` ) : (
    `
    Once you are funded, you will continue to receive ongoing support from <u><a href="https://www.forexanalytix.com" target="_blank">Forex Analytix</a></u>. You will also have the ability to join as a guest on our world-renowned FACE (Forex Analytix Community Experience) daily shows – as a speaker and success story. We are here to help ensure your continued success in the markets. <b>We want you to succeed!</b>
    <br/>
    <br/>
    Our recruitment process is 100% based on merit. We don’t care where you live, what your education or employment history is, your age or other demographics. We want to give equal opportunity to everyone who wants to be a trader.
    <br/>
    <br/>
    While trading, you will have access to advanced performance analytics in an intuitive user dashboard. Having this information readily available will increase your efficiency and further enhance your trading performance.
    `)
  }
}

export default OurApproachContent;
