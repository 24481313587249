import React from "react"

function ClientTerms() {

  return (
    <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
      <h2 className="text-2xl text-secondary font-bold mb-4 underline">
        CLIENT TERMS, CONDITIONS AND POLICIES
      </h2>
      <p>
        Welcome to Prop Account, LLC! The “Company” provides you (“you” or the “Trader”) with a limited license to use the services (the “Services”) offered by the Company subject to the terms and conditions contained herein (the “Agreement”).
        <br />
        <br />
        This Agreement is a legally binding contract, and you have a duty to read this Agreement before using the accessing the Services offered by the Company. By using the Services, you are agreeing to the terms and conditions contained within this Agreement.
        <br />
        <br />
        The Company reserves the right to suspend, replace, modify, amend, or terminate this Agreement at any time and within its sole and absolute discretion. In the event The Company replaces, modifies, or amends this Agreement, your continued use of the Services after a change in the Effective Date of said changes will constitute your agreement to any replacement, modification, or amendment to this Agreement.
        </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Trader Representations
      </h2>
      <p>
        By using the Services, you represent that you at least eighteen (18) years old and are of sound mind and that you have the capacity to agree to and uphold the terms and conditions contained within this Agreement. If you use the Services on behalf of a business entity or other third-party, then you represent that you have actual authority to act as an agent of that business entity or third-party, and that you have the right and ability to agree to and bind that third-party or business entity to the terms of this Agreement on its behalf.
      </p>
      <br />
      <p>
        You represent that your use of the Services does not violate any law, regulation, ordinance, statute, or treaty that is applicable to individuals or business entities located in the jurisdiction in which you live. You further represent that you are not prohibited from entering into this Agreement by the terms of any preexisting agreement.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Limited License
      </h2>
      <p>
        The Company provides you with limited, non-exclusive, non-sublicensable, non-assignable, revocable, and royalty-free license to use the Services for its customary and intended purposes. You are expressly prohibited from scraping, framing, hacking, reverse engineering, crawling, or aggregating the Services, the Company Website, whether in whole or in part, without the prior written consent of the Company.
      </p>
      <br />
      <p>
        You acknowledge and agree that your limited use of the Services does not entitle you to any license or intellectual property rights to any technology, intellectual property, copyrights, trademarks, or trade secrets of the Company or any third-party contractor thereof. You acknowledge and agree that your use of the Services is limited by the terms of this Agreement, and you expressly agree that you will not use the Services in any manner that is not expressly authorized under the terms of this Agreement. The Company reserves all of its rights not expressly granted through this Agreement.
      </p>
      <br />
      <p>
        This license is revocable at any time, and any rights not expressly granted in this Agreement are reserved for the Company.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Prohibited Uses
      </h2>
      <p>
        You are expressly prohibited from using the Services to violate any law, statute, ordinance, regulation, or treaty, whether local, state, provincial, national, or international, or to violate the rights of a third-party, including, but not limited to intellectual property rights, privacy rights, rights of publicity, or other personal or proprietary rights.
      </p>
      <br />
      <p>
        Additionally, you are expressly prohibited from scraping, crawling, framing, posting unauthorized links to, aggregating, hacking, performing denial of service (DOS) attacks on, reverse engineering, or circumventing technological protection measures of the Services or the Company website.
      </p>
      <br />
      <p>
        You are also prohibited from using the Services or the Company website to transmit unsolicited commercial emails to third parties or Traders of the Company. While The Company is not responsible for any such content posted by its Traders and does not have the affirmative obligations to monitor such content, it does reserve the right to remove them.
      </p>
      <br />
      <p>
        You are also prohibited from using any trading strategy that is expressly prohibited by the Company or the Brokers it uses. Such prohibited trading (“Prohibited Trading”) shall include, but not be limited to:


        <br/><br/>• Exploiting errors or latency in the pricing and/or platform(s) provided by the Broker
        <br/><br/>• Utilizing non-public and/or insider information
        <br/><br/>• Front-running of trades placed elsewhere
        <br/><br/>• Trading in any way that jeopardizes the relationship that the Company has with a broker or may result in the canceling of trades
        <br/><br/>• Trading in any way that creates regulatory issues for the Broker
        <br/><br/>• Utilizing any third-party strategy, off-the-shelf strategy or one marketed to pass challenge accounts
        <br/><br/>• Utilizing one strategy to pass an assessment and then utilizing a different strategy in a funded account, as determined by the Company in cooperation with Prop Account, LLC at their discretion
        <br/><br/>• Holding a Single Share Equity CFD position into an earnings release pertaining to that underlying equity.  To avoid being in breach of this rule, you must close all such Single Share Equity CFD positions by 3:50 pm Eastern Time on the day of the release, if an aftermarket release, or on the preceding day, if a before market open release.  Violation of this rule will constitute an immediate, hard breach of your account and any gain or loss on said position will be removed from any profit calculations.
        <br/><br/>• Entering into an Equity CFD at or near the end of the trading day with intent of profiting from the marketing gap between when the market closes and reopens on the subsequent trading day, as determined by the Company in its sole and absolute discretion.[JH1]
        <br/><br/>• Attempting to arbitrage an assessment account with another account with the Company or any third-party company, as determined by the Company in its sole and absolute discretion.

      </p>
      <br />
      <p>
        <br />
        If the Company detects that your trading constitutes Prohibited Trading, your participation in the program will be terminated and may include forfeiture of any fees paid to the Company. Additionally, and before any Trader shall receive a funded account, the trading activity of the Trader under these Terms and Conditions shall be reviewed by both the Company and the Broker to determine whether such trading activity constitutes Prohibited Trading. In the case of Prohibited Trading, the Trader shall not receive a funded account.
      </p>
      <p>
        <br />
        Additionally, the Company reserves the right to disallow or block any Trader from participating in the program for any reason, in the Company’s sole and absolute discretion.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Education
      </h2>

      <p>
        The Company does not provide any trader education. The intent of The Company is to identify individuals with a talent for trading. No live trading is provided directly by the Company. Such Traders who pass an assessment offered by the Company shall be allocated capital to trade in a live account under the terms of an agreement with Prop Account, LC.
      </p>
      <br />

      <p>
        Although The Company may provide data, information, and content relating to investment approaches and opportunities to make trades, such data, information and content is provided solely for general informational and educational purposes. The Company does not invite the Trader to take any action based upon any of the information and materials provided on by the Company; you should not construe any such data, information, or content as investment, financial, tax, legal, or other kind of advice.
      </p>
      <br />

      <p>
        The Company further does not make any representations that any data, information, and content on the Company website is accurate or complete. You alone will bear the sole responsibility of evaluating the merits and risks associated with using any such data, information, and content. As such, you agree not to hold the Company liable for any possible claims of damages that may arise from any decision that you make based upon the use of data, information, and content on the Company website.
      </p>
      <br />

      <p>
        While the Company does not provide you with the opportunity to invest actual currency, the Company wants to make sure you understand the risks involved with traditional investing. You should be aware that the risk of trading and investing is high and substantial. It can work for you as well as against you. It may or may not lead to substantial losses. Additionally, past performance is not indicative of future results.
      </p>
      <br />

      <p>
        As such, you should carefully consider whether trading and investing is right for you depending on your investment objectives, level of experience, and risk appetite. If you are unsure, you should consult with a financial advisor and/or tax advisor.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Account Creation
      </h2>

      <p>
        In order to register as a Trader, you may be asked to provide personal information, including, but not limited to your name, email address, mailing address, phone number, date of birth and a username and password for an account that is unique to you. The information provided is subject to the Company’s privacy policy accessible below.
      </p>
      <br />

      <p>
        The account will be personal to You, and You cannot share it with anybody else. You also may not purchase an account on behalf of a third party or have an account purchased for you by a third party. You will be responsible for maintaining the confidentiality of your username and password. If you suspect that your account has been breached, you must immediately notify The Company.
      </p>
      <br />

      <p>
        Traders are limited to one active account per challenge level, absent prior written approval.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Purchases and Refunds
      </h2>

      <p>
        The Company may provide products, services, subscriptions, or access to certain portions to the Company’s website at a monetary cost. Prices and availability are subject to change without notice. The Company may allow for such purchases within its website or via a white label affiliate. It is your responsibility to thoroughly read and understand any such terms and conditions.
      </p>
      <br />

      <p>
        By making any such purchases, you agree that the Company has no responsibility and acquires no liability for any claim related to your purchases. Upon the complete purchase of a product, service, subscription, or access to certain portions of the Company website, the Company will make any said product, service, or access will be available to you following the approved transaction. 
      </p>
      <br />

      <p>
        There are no refunds on any Services purchased from the Company. If you, as a purchaser, are deemed “high risk” by our payment processors we may require you to provide additional documentation or information in order to proceed with the assessment. Failure to provide the requested documentation and information within twenty-four (24) hours of said request may result in your use of and access to the Services being revoked. The documentation and information supplied does not guarantee that this status will be revoked and may still be subject to ineligibility.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Guidelines
      </h2>

      <p>
        The Company will display the guidelines associated with the Services on the Company’s website and via email, upon becoming a Trader. These guidelines, which may change from time to time in The Company’s sole discretion, are incorporated in whole into this Agreement. The Company makes absolutely no promise, guarantee, or warranty, express or implied, as to any promise to future employment as a trader, monetary payments, or any other type or kind of compensation or award for your performance as a Trader.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Trademarks
      </h2>

      <p>
        You acknowledge and agree that any and all trademarks, trade names, design marks, or logos displayed on the Company website by the Company, are common law or registered trademarks owned by or licensed to the Company. You are expressly prohibited from using the trademarks of the Company to cause confusion, cause mistake, deceive consumers, or from falsely designating the origin of, source of, or sponsorship of your goods or services. You are further prohibited from using the trademarks of the Company in domain names, keyword advertisements, trigger keyword advertisements, or in meta tags. All other trademarks, trade names, design marks, or logos are the property of their respective owners.
      </p>
      <br />

      <p>
        You acknowledge and agree that the Company’s website, its suppliers and licensors expressly reserve all intellectual property rights in all text, programs, products, processes, technology, content and other materials, which appear on the Company’s website. Access to this website does not confer and shall not be considered as conferring upon anyone any license under any of the Company’s or any third party’s intellectual property rights. All rights, including copyright, in this website are owned by or licensed to us or third-party suppliers. Any use of this Website or its contents, including copying or storing it or them in whole or part, other than for your own personal, non-commercial use is prohibited without the permission of The Company. You cannot modify, distribute or re-post anything on this website for any purpose.
      </p>

      <br />
      <p>
        The Company names and logos and all related products and services and our slogans are the trademarks or service marks of the Company or licensed to the Company. All other marks are the property of their respective companies. No trademark or service mark license is granted in connection with the materials contained on the Company Website. Access to the Company website does not authorize anyone to use any name, logo or mark in any manner.
      </p>
      <br />

      <p>
        All materials, including images, text, illustrations, designs, icons, photographs, programs, audio clips or downloads, video clips and written and other materials that are part of this Website (collectively, the “Contents”) are intended solely for personal, non-commercial use. No right, title or interest in any downloaded materials or software is transferred to You as a result of any such downloading or copying. You may not reproduce (except as noted above), publish, transmit, distribute, display, modify, create derivative works from, sell or participate in any sale of or exploit in any way, in whole or in part, any of the contents, the Company website or any related software. All software used on the Company website is the property of the Company or its suppliers and protected by laws of The United States of America. Any other use, including the reproduction, modification, distribution, transmission, republication, display, or performance, of the Contents on the Company website is strictly prohibited. Unless otherwise noted, all Contents are copyrights, trademarks and/or other intellectual property owned, controlled or licensed by the Company, one of its affiliates or by third parties who have licensed their materials to us and are protected by laws of The United States of America. The compilation (meaning the collection, arrangement, and assembly) of all Contents on the Company website is the exclusive property of the Company and is also protected by the laws of The United States of America.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Disclosure Statement
      </h2>

      <p>
        Before deciding to participate in financial markets, you should carefully consider your investment objectives, level of experience and risk appetite. Most importantly, do not invest money you cannot afford to lose.
      </p>
      <br />

      <p>
        There is considerable exposure to risk in any over-the-counter transaction, including, but not limited to, leverage, creditworthiness, limited regulatory protection and market volatility that may substantially affect the price of the products you are trading.
      </p>

      <br />
      <p>
        Moreover, the leveraged nature of over-the-counter trading means that any market movement will have an equally proportional effect on your funds. This may work against you as well as for you.
      </p>
      <br />

      <p>
        There are risks associated with utilizing an Internet-based trading system including, but not limited to, the failure of hardware, software, and Internet connection. The Company is not responsible for communication failures or delays when trading via the Internet. The Company employs backup systems and contingency plans to minimize the possibility of system failure.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Term and Termination
      </h2>

      <p>
        The term of this Agreement will begin when you purchase a Service offered via the Company and will continue until either the Company terminates your access to the Services or you stop using the Services.
      </p>
      <br />

      <p>
        The Company reserves the right to terminate the Services or your access to the Company website in its sole and absolute discretion and without prior notice.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Disclaimer of Warranties and Limitation of Liability
      </h2>

      <p>
        YOU ACKNOWLEDGE AND AGREE THAT THE SERVICE AND COMPANY WEBSITE ARE PROVIDED ON AN “AS-IS” BASIS AND WITHOUT WARRANTY OF ANY KIND, INCLUDING, BUT NOT LIMITED TO WARRANTIES OF TITLE, MERCHANTABILITY, ACCURACY, FITNESS FOR A PARTICULAR PURPOSE, SECURITY, AND NON-INFRINGEMENT. WHEREVER PERMITTED BY LAW, YOU ACKNOWLEDGE THAT THE COMPANY WILL NOT BE HELD RESPONSIBLE OR LIABLE FOR ANY CLAIMS, DAMAGES, JUDGMENTS, CHARGES, OR FEES ARISING OUT OF OR RELATED TO INFORMATION ON THE WEBSITE AND YOUR USE OF OR ACCESS TO THE SERVICE OR THE COMPANY WEBSITE, INCLUDING, BUT NOT LIMITED TO COMPENSATORY DAMAGES, CONSEQUENTIAL DAMAGES, SPECIAL DAMAGES, INCIDENTAL DAMAGES, PUNITIVE DAMAGES, EXEMPLARY DAMAGES, COSTS AND ATTORNEYS’ FEES, DAMAGES ARISING OUT OF ERRORS OR OMISSIONS, AND DAMAGES ARISING OUT OF THE UNAVAILABILITY OF THE WEBSITE OR DOWNTIME. YOU ACKNOWLEDGE THAT YOUR USE OF THE SERVICES AND/OR THE COMPANY WEBSITE IS AT YOUR SOLE RISK AND THAT THE COMPANY’S LIABILITY IS LIMITED TO THE AMOUNT THAT YOU PAID TO USE THE SERVICES OR $1,000, WHICHEVER IS LESS.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Indemnification
      </h2>

      <p>
        You agree to indemnify, defend, and hold harmless The Company, its officers, shareholders, directors, employees, subsidiaries, affiliates, white label users, and representatives from any and all losses, including, but not limited to costs and attorneys’ fees arising out of or related to your use of the Website; your violation of any term or condition of this Agreement; your violation of the rights of third parties, including but not limited to intellectual property rights or other personal or proprietary rights; and violation of any law, statute, ordinance, regulation, or treaty, whether local, state, provincial, national or international.
      </p>
      <br />

      <p>
        Your obligation to defend The Company will not provide you with the ability to control The Company’s defense, and The Company reserves the right to control its defense, including its choice of counsel and whether to litigate or settle a claim subject to indemnification.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Arbitration
      </h2>

      <p>
        You acknowledge and agree that any controversy or claim arising out of or related to this Agreement, including any claim or controversy concerning interpretation of this Agreement or your use of this Services, will be settled by arbitration pursuant to the most recently effective commercial arbitration rules of the American Arbitration Association (AAA). This arbitration proceeding will be decided by a single arbitrator randomly selected from a list of neutral arbitrators maintained by the AAA. Judgement on any award rendered by the arbitrator may be entered in any court of competent jurisdiction. The arbitrator will be provided with the right to award costs and attorneys’ fees to the prevailing party. This arbitration will be held in Quebec and both the Company and Trader agree that they will be required to be present in Quebec for arbitration under the terms of this Agreement and hereby submit to exclusive personal jurisdiction in Quebec. The arbitrator will apply the laws of Quebec in deciding any controversy or claim pursuant to this Agreement.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Force Majeure
      </h2>

      <p>
        The Company shall not be liable to Trader for any claims, losses, damages, costs or expenses, including attorneys’ fees, caused, directly or indirectly, by any events, actions or omissions, including, without limitation, claims, losses, damages, costs or expenses, including attorneys’ fees, resulting from civil unrest, war, insurrection, international intervention, governmental action (including, without limitation, exchange controls, forfeitures, nationalizations, devaluations), natural disasters, acts of God, market conditions, inability to communicate with any relevant person or any delay, disruption, failure or malfunction of any transmission or communication system or computer facility, whether belonging to the Company, Trader, or third-party service provider.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Survivability
      </h2>

      <p>
        The representations, warranties, duties, and covenants made by you under this Agreement will survive the termination of this Agreement or the Services, including, but not limited to your duty to indemnify and defend the Company.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Severability
      </h2>

      <p>
        In the event that any term or condition of this Agreement is deemed invalid or unenforceable by the court of competent jurisdiction, the remaining terms and conditions of this Agreement will remain in full force and effect.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Interpretation
      </h2>

      <p>
        This Agreement will be deemed to have been drafted by both parties, and the terms and conditions of this Agreement will not be interpreted against its drafte
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Assignment
      </h2>

      <p>
        You are expressly prohibited from assigning your rights and duties under this Agreement. The Company reserves the right to assign its rights and duties under this Agreement, including in a sale of the Company or its Services.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Waiver
      </h2>

      <p>
        No term or condition of this Agreement or breach of this Agreement will be deemed to have been waived or consented to, unless said waiver is in writing and signed by the party to be charged.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Entire Agreement
      </h2>

      <p>
        This Agreement contains the entire agreement between the Company and the Trader regarding the use of the Services and supersedes all prior understandings, agreements, or representations between the Company and Trader, whether written or oral.
      </p>
      <br />
      <br />
      <br />

      <h2 className="text-2xl text-secondary font-bold mb-4 underline">
        PRIVACY POLICY
      </h2>
      <p>
        Prop Account, LLC (the “Company,” “we,” “us,” or “our”) has created this privacy notice to explain our data collection and processing practices. By using our Services, you acknowledge and agree to our policies and procedures regarding personal information. If you have any questions or concerns, feel free to reach out to us at support@propaccount.com. If you do not agree with our policies, please refrain from using our Services.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        What Information Do We Collect?
      </h2>

      <p>
        Personal information is gathered from you through voluntary means when you register on our Services, show interest in learning about us or our products/services, take part in activities on the Services, or make contact with us.
      </p>
      <br/>
      <p>
        The personal information we collect is based on your interactions with us and the Services, the choices you make, and the products/features you use. This can include:
        <br />• Names
        <br />• Phone numbers
        <br />• Email addresses
        <br />• Physical address
        <br />• Billing addresses
      </p>

      <br />
      <p>
        <b>Sensitive Information:</b> We do not collect sensitive information such as social security numbers or other information not necessary to the operation of the Company’s business.
        <br/><b>Payment Information:</b> In case of any purchases, we may gather data necessary to process your payment, such as the payment instrument number and security code linked to your payment method. The storage of all payment information is handled by our payment processors. Their respective privacy notices can be accessed on their websites.
        It’s crucial that all personal information you provide us is truthful, comprehensive, and up to date. In the event of any changes to your personal information, it’s your responsibility to notify us.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Information Automatically Collected
      </h2>

      <p>
        During your visit, usage, or navigation of the Services, certain information is automatically collected by us. This information, while not revealing your specific identity (e.g. name or contact details), may include device and usage data such as IP address, browser and device specifications, operating system, language preferences, source URLs, device name, country, location, usage information of our Services, and other technical details. This information is mainly utilized for ensuring the security and functioning of our Services, as well as for internal analysis and reporting purposes.
      </p>
      <br />

      <p>
        Similar to other businesses, we also gather information through the use of cookies and similar technologies.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        We Collect Information
      </h2>

      <p>
        <b>Log and Usage Data:</b> This refers to information related to service, diagnostics, usage, and performance, which our servers automatically gather when you access or utilize our Services.
      </p>
      <br />
      <p>
        This data is stored in log files and may include details like your IP address, device information, browser type and settings, and information about your activities within the Services (such as timestamps of usage, pages/files viewed, searches performed, and other actions like feature usage), and device event information (such as system activity, error reports, and hardware configurations), depending on your interaction with us.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        How Do We Process Your Information?
      </h2>

      <p>
        Your personal information is processed by us for several purposes, based on your interaction with our Services. These may include:
        <br />• Easing account creation and authentication and identifying fraudulent activity
        <br />• We may use your information to seek feedback and communicate with you regarding your usage of our Services. Processing of your information may also occur when necessary to protect someone’s vital interests, for instance, to prevent harm.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        What Legal Bases Do We Rely On To Process Your Information?
      </h2>

      <p>
        We process your personal data only when necessary and when we have a valid legal justification, such as with your consent, to follow laws, provide services, fulfill contracts, protect your rights, or serve our legitimate business interests, inclusive of those set forth in our Terms & Conditions and to protect the Company against fraud.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        This section applies to Users located in EU or UK
      </h2>

      <p>
        The GDPR and UK GDPR mandate us to outline the legal grounds for processing your personal data. Therefore, we process your information based on the following legal grounds:
      </p>
      <br/>
      <p>
        <b>Consent:</b> We may process your information if you have provided us with consent to use your personal information for a specific purpose. You have the right to revoke your consent at any time. To do so, simply reach out to us through the contact form or send an email to support@propaccount.com.
      </p>
      <br/>
      <p>
        <b>Legitimate Interest:</b> We may use your information if it is necessary for our legitimate business interests, provided that these interests do not override your interests or basic rights and freedoms, inclusive of those set forth in our Terms & Conditions and to protect the Company against fraud.
      </p>
      <br/>
      <p>
        <b>Legal Obligation:</b> We may handle your information to fulfill our legal obligations, such as cooperating with law enforcement or regulatory bodies, protecting our legal rights, or providing your information as evidence in relevant legal proceedings.
      </p>
      <br/>
      <p>
        <b>Vital Interests:</b> We may also process your information if it is crucial to safeguarding your vital interests or the vital interests of others, for example in emergency situations where safety is at risk.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        If you are located in Canada, this section applies to you
      </h2>

      <p>
        We can only process your information if you have either given us express consent for a specific purpose or if it can be assumed from the circumstances (implied consent). However, you have the right to revoke this consent at any time. In exceptional cases where the law permits, we may process your information without your consent such as:
        <br />• it is in an individual’s best interests and consent cannot be obtained promptly,
        <br />• it is necessary for fraud investigation and prevention,
        <br />• it is required for commercial transactions under certain conditions,
        <br />• it is contained in a witness statement for insurance claim evaluation and settlement,
        <br />• it is needed to identify injured, ill, or deceased people and communicate with next of kin,
        <br />• there are reasonable grounds to believe an individual has been or may be a victim of financial abuse,
        <br />• collection and use with consent would jeopardize information availability or accuracy and collection is necessary for breach of agreement or violation investigation,
        <br />• disclosure is required by law, such as a subpoena or court order,
        <br />• it was created in the course of employment, business, or profession and consistent with the information’s original purpose,
        <br />• its sole purpose is for journalism, art, or literature,
        <br />• it is publicly available and required by law.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        When And With Whom Do We Share Your Personal Information
      </h2>

      <p>
        Your personal information may be shared in the following cases:
      </p>
      <br />

      <p>
        <b>Business Transfers:</b> During negotiations or completion of a merger, sale of assets, financing, or acquisition of our company or a part of it, your information may be shared or transferred to another company.
      </p>
      <br />

      <p>
        <b>Working with Vendors:</b> To provide the Services, the Company works with various third-parties.  The Company may share your personal information to the extent it ensures adherence to the Company’s Terms & Conditions and to otherwise protect against fraud or manipulation involving the Company or third-parties.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Do We Use Cookies and Other Tracking Technologies?
      </h2>

      <p>
        The use of cookies and other tracking technologies (e.g. web beacons, pixels) may allow access or storage of information. Our Cookie Notice provides detailed information on our usage of these technologies and offers options to decline certain cookies.
      </p>
      <br />
      <p>
        We also utilize various tracking methods such as Google AdWords, Meta Advertising, and Google Analytics to gather and store data on user interactions with our websites.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        How Long Do We Keep Your Information?
      </h2>

      <p>
        We retain your personal information only as long as necessary or legally allowed, including for tax, accounting, or other legal reasons. When our business no longer requires it, we will either delete or anonymize your personal information. If deletion is not feasible, we will securely store it and restrict access until it can be deleted.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        How Do We Keep Your Information Safe?
      </h2>

      <p>
        We have taken necessary and reasonable technical and organizational measures to secure personal information in our possession. However, the Internet and information storage technology are not foolproof, so we cannot guarantee that your information will not be accessed, stolen, or altered by unauthorized third parties.
      </p>
      <br />

      <p>
        While we strive to protect your personal information, it is your responsibility to ensure the safety of the information you transmit through our Services. Use caution when using the Services and only in a secure environment.
      </p>


      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Do We Collect Information from Minors?
      </h2>

      <p>
        We do not intentionally gather information from or target individuals under 18. By using our services, you certify that you are 18 or older, or the parent/guardian of a minor using the services with your consent.
      </p>
      <br />

      <p>
        In case we discover any personal information from minors, we will promptly disable their account and erase the data from our records. If you become aware of any information collected from minors, please contact us at support@propaccount.com.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        What Are Your Privacy Rights?
      </h2>
      <p>
        In some regions with applicable data protection laws (such as the EEA, UK, and Canada), you have certain rights, such as:
      </p>
      <p>
        <br/>(i) Requesting access and a copy of your personal information.
        <br/>(ii) Requesting correction or deletion.
        <br/>(iii) Limiting the processing of your personal information and data portability (if applicable).
        <br/>(iv) Objecting to the processing of your personal information under certain circumstances.
        If you wish to make such a request, please contact us.
        <br/>
        <br/>
        If you wish to make such a request, please contact us.
      </p>
      <p>
        If you reside in the European Economic Area or the UK and think that your personal information is being mishandled, you can file a complaint with your local data protection authority.
        <br/>
        Find their contact details at: 
        <a className="underline" href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
          https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
        </a>
        <br/>
        If you are in Switzerland, the data protection authorities’ information can be found at: 
        <a className="underline" href="https://www.edoeb.admin.ch/edoeb/en/home.html">
          https://www.edoeb.admin.ch/edoeb/en/home.html.
        </a>
      </p>

      <br />
      <p>
        <b>Withdrawing your Consent:</b> If your personal information is being processed based on your consent, either expressed or implied as per the relevant laws, you have the right to withdraw it at any moment.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Opting out of marketing and promotional communications
      </h2>

      <p>
        To stop receiving our marketing and promotional emails, simply click the unsubscribe link in the emails we send you. You will then be removed from our marketing lists. Note that we may still send you non-marketing communications such as service-related updates, account administration, responding to your service requests, etc.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Account Information
      </h2>

      <p>
        To review or change information in your account or close it, follow these steps:
        <br />
        <br />• Log in to your account settings and update your user account.
        <br />• Contact us using the provided contact information.
      </p>
      <br />
      <p>
        Note: Upon request, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files for fraud prevention, problem resolution, investigations, enforcement of legal terms, and compliance with legal requirements.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Cookies and similar technologies
      </h2>
      <p>
        Web browsers are typically configured to accept cookies by default. You can choose to remove or reject cookies, but this may impact certain features or services of our Services. To opt out of interest-based ads by advertisers on our Services, go to https://youradchoices.com/control.
        For privacy-related questions or comments, email us at support@propaccount.com.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Controls For Do-Not-Track Features
      </h2>
      <p>
        Many web browsers, mobile OS and apps have a Do-Not-Track (“DNT”) feature to signal your preference for not having your online browsing activities monitored and collected. However, as there is no agreed technology standard for DNT, we do not currently respond to DNT browser signals or any other mechanism that communicates your preference not to be tracked. If a future standard for online tracking is adopted, we will update this privacy notice and inform you.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Do California Residents Have Specific Privacy Rights?
      </h2>
      <p>
        As a California resident, you have specific rights. While the Company does not solicit or accept minors under 18 years of age, if you are a minor under 18 years old and have a registered account with us, you have the right to request the removal of unwanted data from your account.
      </p>
      <br/>
      <p>
        To make this request, please reach out to us via email at support@propaccount.com with your email address and a statement confirming your residency in California. We will take steps to ensure that the data is not publicly displayed on our services, but please note that it may not be fully or entirely deleted from all of our systems (such as backups, etc.).
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        CCPA Privacy Notice
      </h2>
      <p>
        According to the California Code of Regulations, a “resident” is defined as:
        <br />
        <br />(1) any individual who is in California for purposes other than temporary or transitory, and
        <br />(2) any individual who has a permanent home in California but is temporarily outside the state for a transitory purpose.
      </p>
      <br />
      <p>
        Individuals who do not meet the definition of “resident” are defined as “non-residents.” If you are considered a “resident” under this definition, certain rights and obligations must be followed in regards to your personal information.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        How Do We Use and Share Your Personal Information?
      </h2>
      <p>
        For additional details on our data collection and sharing procedures, please refer to our privacy notice. To get in touch with us, you can email us at support@propaccount.com, or find the contact information at the bottom of this document.
      </p>
      <br />
      <p>
        If you choose to use an authorized agent to opt out, please note that we may reject the request if the authorized agent cannot provide proper proof of their authorization to act on your behalf.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Will Your Information Be Shared with Anyone Else?
      </h2>
      <p>
        We may share your personal information with our service providers in accordance with the terms outlined in our contracts with them. These service providers are for-profit entities that process information on our behalf while complying with the CCPA’s rigorous privacy standards.
      </p>
      <br />
      <p>
        We may use your personal information for internal business purposes, such as technological advancement and demonstration research, but this is not considered “selling” your personal information.
      </p>
      <br />
      <p>
        In the past 12 months, the Company has not disclosed, sold, or shared any personal information with third parties for business or commercial purposes. We will not sell or share personal information about website visitors, users, or other consumers in the future.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Your Rights with Respect to Your Personal Data.<br/>
        Right to request deletion of the data — Request to delete
      </h2>
      <p>
        You have the right to request the deletion of your personal information. We will comply with your request, subject to certain exceptions under the law, such as (but not limited to) when another consumer is exercising their right to free speech, when we are obligated to comply with a legal requirement, or when processing is necessary to prevent illegal activities.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Right to be informed — Request to know
      </h2>
      <p>
        You have the right to know:
        <br />
        <br />• If we collect and use your personal information,
        <br />• The types of personal information we collect,
        <br />• The reasons for collecting the personal information,
        <br />• Whether we sell or share personal information with third parties,
        <br />• The types of personal information that have been sold, shared, or disclosed for business purposes,
        <br />• The third parties to whom the personal information was sold, shared, or disclosed for business purposes,
        <br />• The purpose of collecting, selling, or sharing personal information,
        <br />• The specific personal information we have collected about you.
        <br />
      </p>
      <br />
      <p>
        By law, we are not obligated to supply or remove consumer information that has been de-identified in response to a consumer’s request, or to re-identify individual data to confirm a consumer’s request.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
      </h2>
      <p>
        We will not engage in discrimination if you exercise your privacy rights. You have the right to limit the use and disclosure of sensitive personal information, and we do not handle consumers’ sensitive personal information.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Verification Process
      </h2>
      <p>
        To confirm your identity when we receive your request, we may need to verify that you are the same person whose information we have on record. This verification process may require us to ask for additional information from you that matches what we already have.
      </p>
      <br />
      <p>
        For instance, based on the type of request you submit, we may request specific information to verify, or reach out to you using a previously provided communication method, such as phone or email. Other methods of verification may also be employed depending on the situation.
      </p>
      <br />
      <p>
        The personal information you provide in your request will only be utilized to verify your identity and authority to make the request. We will aim to minimize the need to request additional information from you for verification purposes.
      </p>
      <br />
      <p>
        If our existing information is insufficient to verify your identity, we may ask for additional information to verify your identity, as well as for security and fraud prevention purposes. Once the verification process is completed, any additional information you provide will be promptly deleted.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Other privacy rights
      </h2>
      <p>
        <br />• You have the right to object to the processing of your personal information.
        <br />• You have the right to request correction of your personal data if it is inaccurate or outdated, or to request that processing be limited.
        <br />• You may appoint an authorized agent to make a request under the CCPA on your behalf, but we may reject the request if the authorized agent fails to provide proof of their authorization to act on your behalf.
        <br />• You may request that your personal information not be sold or shared with third parties in the future. We will respond to your opt-out request within 15 days from the date of receipt.
        <br />
      </p>
      <br />
      <p>
        To assert these rights, contact us via support@propaccount.com, or refer to the information at the bottom of this document. If you have a concern about our data handling, we’d like to hear from you.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        Do We Make Updates to This Notice?
      </h2>
      <p>
        This privacy notice may be revised periodically, with the updated version indicated by a new “Revised” date. It will become effective immediately upon release. We may notify you of significant changes by posting a prominent notice or sending you a notification. It’s important to regularly review this privacy notice to stay informed of how we safeguard your information.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        How Can You Contact Us About This Notice?
      </h2>
      <p>
        For questions or comments regarding this notice, you may reach us via email at support@propaccount.com 
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        How Can You Review, Update, Or Delete the Data We Collect From You?
      </h2>
      <p>
        Depending on your country’s laws, you may have the right to access, modify, or delete the personal information we have collected from you. To do so, please send your request via email at support@propaccount.com.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        REFUND POLICY
      </h2>
      <p>
        After a cleared payment on the purchase of one of our programs occurs, you will receive an email with the login details to access your trading platform. Once this information is emailed to you, no refund will be given. In some special circumstances, we will provide a refund if there were no trades placed on the account, for assistance, please contact our email support.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        DISPUTE POLICY
      </h2>
      <p>
        Clients who improperly dispute charges or request chargebacks with their bank will be permanently banned from the Platform. Please contact our email support if you have any questions.
      </p>

      <h2 className="text-2xl text-secondary font-bold mb-4 mt-10">
        ACCEPTANCE OF THIS POLICY
      </h2>
      <p>
        It is your responsibility to familiarize yourself with this refund policy. By placing an order for any of our products, you indicate that you have read this refund policy and that you agree with and fully accept the terms of this refund policy. If you do not agree with or fully accept the terms of this refund policy, we ask that you do not place an order with us. Please contact us at support@propaccount.com should you have any questions regarding our refund policy.
      </p>



    </div>
  )
}

export default ClientTerms
