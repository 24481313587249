import * as React from "react"
import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import {FaqsItems} from "./faqs-items"

const Faqs = () => {


  return (
    <div className="bg-gray-0" id="faq">
      <div className="px-4 py-12 mx-auto max-w-7xl sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
            <h2 className="text-3xl font-extrabold text-center text-gray-900 sm:text-4xl">
              Frequently asked questions
            </h2>
          {FaqsItems.map((section, index) => (
            <div className="mt-6 space-y-6 divide-y divide-gray-200" key={index}>
              <div className="text-center pt-6">
                {section.entryTitle}
              </div>
              <dl className="mt-6 space-y-6 divide-y divide-gray-200">

                  {section.items && section.items.map((faq, index) => (
                    <Disclosure as="div" key={index} className="pt-6">
                      {({ open }) => (
                        <>
                          <dt className="text-lg">
                            <Disclosure.Button className="flex items-start justify-between w-full text-left text-black">
                              <span className="font-medium text-black">
                                {faq.question}
                              </span>
                              <span className="flex items-center ml-6 h-7 text-green">
                                <ChevronDownIcon
                                  className={open ? "h-6 w-6 transform -rotate-180" : "h-6 w-6 transform -rotate-0"}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="pr-6 pl-6 pt-2 pb-2 mt-2 bg-gray-50">
                            <div className="text-base text-gray-900"
                              dangerouslySetInnerHTML={{__html: faq?.answer}}>

                            </div>

                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}

              </dl>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Faqs
