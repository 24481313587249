import React from "react"

import img from "../images/our-technology.jpg"

function OurTechnology({ data }) {
  const title = 'our-technology'

  return (
    <div className="relative bg-black">
      <div
        className="md:right-0 h-56 bg-indigo-600 sm:h-72 md:absolute md:h-full md:w-1/2">
          <img
            className="object-cover w-full h-full"
            src={img}
            alt={title}
          />
      </div>
      <div className="relative px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-16">
        <div className="md:mr-auto md:pr-10 md:w-1/2">
            <h2 className="text-base font-semibold tracking-wider text-gray-100 uppercase">
              ALWAYS ONLINE
            </h2>
            <p className="mt-2 text-3xl font-extrabold tracking-tight text-green sm:text-4xl">
              Our technology
            </p>
            <p className="mt-3 text-lg text-gray-100">
              There is no other firm that will offer you an app to access analysis and trade ideas while you are on the go. Notifications, support and resistance levels breached, breaking news, and access to the chatrooms on your mobile device is paramount.
            </p>
            <div className="mt-8">
              <div className="inline-flex rounded-md shadow">
                <a href={'https://www.forexanalytix.com/'}
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent face-blue-bg face-blue-btn-hover"
                >
                  Visit our website
                  <svg
                    className="w-5 h-5 ml-3 -mr-1 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                  </svg>
                </a>
              </div>
            </div>

        </div>
      </div>
    </div>
  )
}

export default OurTechnology
